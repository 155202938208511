$font-family-base: Arial, sans-serif;
$font-family-mono: "Courier New", monospace;

$main-color-green: #0c785e;
$main-color-lightgreen: #6db7a3;
$main-color-darkgreen: #0c3c1c;
$main-color-red: #e01119;

$white: #ffffff;
$black: #000000;
$grey: #767e8f;

$screen-xs: 480px;
$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;

$screen-xs-min: $screen-xs - 1;
$screen-sm-min: $screen-sm - 1;
$screen-md-min: $screen-md - 1;
$screen-lg-min: $screen-lg - 1;
$screen-xl-min: $screen-xl - 1;

$font-size-xs: 12px;
$font-size-sm: 18px;
$font-size-md: 18px;
$font-size-lg: 18px;
$font-size-xl: 18px;
