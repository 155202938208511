.matchDetailsPage {
    .matchDetailsPage__teams {
        display: flex;

        .matchDetailsPage__teams--home {
            display: inline-block;
        }
        .matchDetailsPage__teams--away {
            display: inline-block;
        }

        .teamLogo {
            display: flex;
        }
    }
}
