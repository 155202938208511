@import "./variables.scss";

.homePage {
    width: 100%;
    display: inline-block;

    .homePage__tabs {
        width: 100%;
    }

    .homePage__homeData {
        width: 100%;
        display: flex;

        > * {
            margin: auto;
        }

        .homePage__homeData--standings {
        }
        .homePage__homeData--teamOfTheWeek {
        }
        .homePage__homeData--currentWeek {
            .currentWeek__content {
                width: fit-content;
                margin: auto;
            }
        }

        .homePage__homeData--calendar {
        }

        .homePage__homeData--playerstats {
            display: inline-block;
        }
        .homePage__homeData--spectatorstats {
            display: inline-block;
        }
    }
}

// RESPONSIVE
// 0 - 480px
@media (max-width: $screen-xs-min) {
}

// 0 - 640px
@media (max-width: $screen-sm-min) {
}

// 0 - 1024px
@media (max-width: $screen-lg-min) {
}

// 0 - 1024px
@media (max-width: $screen-xl-min) {
}

// 480px - 640px
@media (min-width: $screen-xs) and (max-width: $screen-sm-min) {
}

// 640px - 1024px
@media (min-width: $screen-sm) and (max-width: $screen-lg-min) {
}

// 1024px - 1280px
@media (min-width: $screen-lg) and (max-width: $screen-xl-min) {
}
