@import "./variables.scss";

.teamDetailsPage {
    overflow: auto;
    width: 100%;
    display: flex;

    .teamDetailsPage--content {
        width: fit-content;
        margin: auto;

        .teamDetailsPage--content--shortcuts {
            display: flex;
            width: 100%;

            > div {
                margin: 20px auto;

                @media (max-width: $screen-xs-min) {
                    margin: 20px 0;
                    width: 20%;
                    text-align: center;
                }
            }
        }

        .teamDetailsPage--content--data {
            width: fit-content;
            margin: auto;
        }
    }
}
