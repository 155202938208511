.refDetailsPage {
    display: inline-block;
    .refDetailsPage--refInfo {
        display: flex;
        width: fit-content;
        margin: auto;

        .refDetailsPage--refInfo--extra {
            display: inline-block;
            text-align: left;
            margin: auto 0;

            span {
                display: block;
            }
        }
    }
}
