@import "./variables.scss";

body {
    margin: 0;
    font-family: $font-family-base;
}

.goHome {
    margin: auto;
    width: fit-content;
}

// RESPONSIVE
// 0 - 480px
@media (max-width: $screen-xs-min) {
}

// 0 - 640px
@media (max-width: $screen-sm-min) {
}

// 0 - 1024px
@media (max-width: $screen-lg-min) {
}

// 0 - 1024px
@media (max-width: $screen-xl-min) {
}

// 480px - 640px
@media (min-width: $screen-xs) and (max-width: $screen-sm-min) {
}

// 640px - 1024px
@media (min-width: $screen-sm) and (max-width: $screen-lg-min) {
}

// 1024px - 1280px
@media (min-width: $screen-lg) and (max-width: $screen-xl-min) {
}
